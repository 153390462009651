

































import { Component, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { logger } from '../../../../../../support'

import { CartModel, CartMutationTypes } from '../../../../contracts'

import { FREE_PAYMENT } from '../../PaymentStep.config'

import { GiftCardInfoElement } from './GiftCard.contracts'
import { giftCardInfoIconsRegistry, GiftCardInfoType } from './GiftCardDriver.config'
import PaymentBaseDriver from '../BaseDriver.vue'
import { PaymentDriver } from '../drivers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<GiftCardDriver>({
  name: 'GiftCardDriver',
  created () {
    if (this.selected === PaymentDriver.GiftCard) {
      this.selectCandidate()
    }
  },
  mounted () {
    this.initDriver()
  }
})
export class GiftCardDriver extends PaymentBaseDriver {
  public giftCardCode: string = ''
  public error: string = ''
  public infoElements: Array<GiftCardInfoElement> = []
  public canApplyGiftCard: boolean = false

  public get orderedInfoElements (): Array<GiftCardInfoElement> {
    return this.infoElements.length > 0
      ? this.infoElements.sort((a, b) => a.order > b.order ? 1 : -1)
      : []
  }

  public get isActivated (): boolean {
    return this.orderedInfoElements.length > 0
  }

  public getGiftCardsValue (cart: CartModel): number {
    return cart.getGiftCard().length > 0
      ? cart.getGiftCard().reduce((acc, curr) => {
        return acc + curr.appliedBalance.value
      }, 0)
      : 0
  }

  public getIcon (iconName: string): VueConstructor | undefined {
    if (!(iconName in giftCardInfoIconsRegistry)) {
      return
    }

    return giftCardInfoIconsRegistry[iconName]
  }

  public get hasErrors (): boolean {
    return this.error.length > 0
  }

  public async activateCode (): Promise<void> {
    this.clearError()

    try {
      this.$emit('saving', true)

      const cart = await this.checkoutService.applyGiftCard(this.cart.id, this.giftCardCode)
      this.$store.commit(CartMutationTypes.SetCartId, cart.id)
      this.$store.commit(CartMutationTypes.SetCart, cart)

      const giftCardValue = this.getGiftCardsValue(cart)

      if (giftCardValue > 0) {
        this.handleGiftCardInfo(GiftCardInfoType.Activated)
      }

      if (cart.getTotalPrice() === 0) {
        this.handleGiftCardInfo(GiftCardInfoType.Exceeded)
        this.handleGiftCardInfo(GiftCardInfoType.ChangeMethod)
      } else {
        this.handleGiftCardInfo(GiftCardInfoType.AdditionalMethod)
      }

      // Apply `free` payment method. (intentionally)
      // This method is not listed among Magento cart.available_payment_methods but Amasty service applies it for such cases when gift cards exceeds cart total value
      if (cart.getTotalPrice() === 0) {
        this.setPaymentMethod([this.cart.id, { code: FREE_PAYMENT }])
        this.$emit('ready', true)
      }
    } catch (e) {
      logger(e, 'warn')
      this.error = (e as Error).message
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * @override
   */
  protected async selectCandidate (): Promise<void> {
    if (!this.isActivated) {
      this.$emit('ready', false)
    }
  }

  private clearError (): void {
    this.error = ''
  }

  private handleGiftCardInfo (information: string): void {
    if (!Object.values(GiftCardInfoType as unknown as string).includes(information)) {
      return
    }

    switch (information) {
      case GiftCardInfoType.Activated:
        this.infoElements.push({
          type: GiftCardInfoType.Activated,
          icon: GiftCardInfoType.Activated,
          content: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.information.activated').toString(),
          order: 10
        })
        break
      case GiftCardInfoType.AdditionalMethod:
        this.infoElements.push({
          type: GiftCardInfoType.AdditionalMethod,
          icon: GiftCardInfoType.AdditionalMethod,
          content: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.information.additionalMethod').toString(),
          order: 20
        })
        break
      case GiftCardInfoType.ChangeMethod:
        this.infoElements.push({
          type: GiftCardInfoType.ChangeMethod,
          icon: GiftCardInfoType.ChangeMethod,
          content: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.information.changeMethod').toString(),
          order: 30
        })
        break
      case GiftCardInfoType.Exceeded:
        this.infoElements.push({
          type: GiftCardInfoType.Exceeded,
          icon: GiftCardInfoType.Exceeded,
          content: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.information.exceeded').toString(),
          order: 20
        })
        break
    }
  }

  private initDriver (): void {
    this.infoElements = []

    if (this.cart.getGiftCard().length === 0) {
      this.giftCardCode = ''
    } else {
      this.giftCardCode = this.cart.getGiftCard()[0].code

      this.handleGiftCardInfo(GiftCardInfoType.Activated)

      if (this.cart.getTotalPrice() === 0) {
        this.handleGiftCardInfo(GiftCardInfoType.Exceeded)
        this.handleGiftCardInfo(GiftCardInfoType.ChangeMethod)
        this.$emit('ready', true)
      } else {
        this.handleGiftCardInfo(GiftCardInfoType.AdditionalMethod)
      }
    }
  }

  @Watch('giftCardCode')
  private onGiftCardCodeUpdate () {
    this.canApplyGiftCard = this.giftCardCode.length > 0
  }

  @Watch('cart')
  private onCartUpdate () {
    this.initDriver()
  }
}

export default GiftCardDriver
