







































import { Component } from 'vue-property-decorator'

import { logger } from '../../../../support'
import { PaymentMethodCode, PaymentMethodPayload } from '../../../../contexts'

import {
  PaymentMethod,
  Przelewy24PaymentMethodDefinition
} from '../PaymentStep/PaymentStep.contracts'
import {
  defaultPaymentDriver,
  PaymentDriver,
  paymentsDriversRegistry
} from '../PaymentStep/drivers/drivers'

import { PaymentStep } from '../PaymentStep'
import { FREE_PAYMENT, GIFT_CARD } from '../PaymentStep/PaymentStep.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 *
 */
@Component<PaymentStepTwo>({
  name: 'PaymentStepTwo',
  components: { PaymentStep },
  created (): void {
    this.loadPaymentsMethods()
    this.isReady = this.isSelectedPayment
  }
})
export class PaymentStepTwo extends PaymentStep {
  public paymentMethods: Przelewy24PaymentMethodDefinition[] = []
  public isReady: boolean = false

  public get hasGiftCard (): boolean {
    return !!this.cart.appliedAmastyGiftCards && !!this.cart.appliedAmastyGiftCards.length
  }

  public get isSelectedPayment (): boolean {
    return this.selectedPayment !== null && this.selectedPayment.code.length > 0 &&
      this.isValidSelected
  }

  public get isValidSelected (): boolean {
    if (!this.selectedPayment) {
      return true
    }

    return this.methods.map(m => m.id).indexOf(this.selectedPayment.code) >= 0
  }

  public considerRememberingCandidate (methodId: string): boolean {
    if (!this.selectedPayment) {
      return false
    }
    let output = false

    switch (methodId) {
      case PaymentMethodCode.GiftCard:
        output = this.hasGiftCard
        break
    }

    return output
  }

  /**
   * Prevent going to the next step when conditions are met:
   * 1. Applied payment method code is `free` AND
   * 2. There are no present gift cards
   * (e.g. gift cards have been removed but `free` method still takes effect)
   */
  public get preventNextStep (): boolean {
    return !!this.cart.selectedPaymentMethod &&
      this.cart.selectedPaymentMethod.code.toString() === FREE_PAYMENT &&
      (this.cart.getGiftCard().length === 0 || this.cart.getTotalPrice() !== 0)
  }

  /**
   * Translate `Przelewy24` methods to common payment method.
   */
  public get translatedPrzelewy24ToPaymentMethods (): PaymentMethod[] {
    return this.paymentMethods.map((method) => {
      return {
        id: String(method.id),
        image: method.imgUrl,
        title: this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${method.id}.title`),
        description:
          this.$t(`front.checkout.organisms.PaymentStep.Przelewy24.${method.id}.description`),
        driver: paymentsDriversRegistry[PaymentDriver.Przelewy24SingleMethod]
      }
    })
  }

  /**
   * Gift card payment method configuration
   */
  public get giftCardPaymentMethod (): PaymentMethod {
    let image = ''
    try { // todo: wrapped in try-cacth, as it breaks backoffice - fix import!
      image = require('../../../../../../../assets/images/payments/giftcard.png')
    } catch (e) {}

    return {
      id: GIFT_CARD,
      image,
      title: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.title'),
      description: this.$t('front.checkout.organisms.PaymentStep.method.giftcard.description'),
      driver: paymentsDriversRegistry[GIFT_CARD]
    }
  }

  public get hasFreeDelivery (): boolean {
    return this.cart.selectedShippingMethod?.price.value === 0
  }

  public get methods (): PaymentMethod[] {
    let unorderedMethods: PaymentMethod[] = this.cart?.availablePaymentMethods
      .filter(({ code }) => this.supportedPaymentMethods.includes(code))
      .map((method: PaymentMethodPayload<string>) => ({
        id: method.code,
        title:
          this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.title${method.code ===
          PaymentMethodCode.OnDelivery && !this.hasFreeDelivery ? '_withPrice' : ''}`),
        description: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.description`),
        image: this.supportedPaymentMethodsIcons[method.code],
        driver: method.code in paymentsDriversRegistry ? paymentsDriversRegistry[method.code]
          : defaultPaymentDriver
      }))
    // Join magento based payment methods with inner Przelewy24 methods.
    unorderedMethods = [...unorderedMethods, ...this.translatedPrzelewy24ToPaymentMethods]

    if (this.useGiftCardsForPayment) {
      unorderedMethods.push(this.giftCardPaymentMethod)
    }

    return this.supportedPaymentMethods
      .map((code) => unorderedMethods.filter((m) => m.id === code)[0])
      .filter(item => item)
      .sort((m1, m2) => {
        if (this.shouldSortMethods) {
          return this.paymentsOrder![m1.id] > this.paymentsOrder![m2.id] ? 1 : -1
        }
        return 1
      })
  }

  public onSaving (value: boolean): void {
    this.$emit('saving', value)
  }

  public setReady (status: boolean) {
    this.isReady = status
  }

  public shouldDeactivateMethod (method: string): boolean {
    return method === GIFT_CARD && this.hasGiftCard
  }

  protected async loadPaymentsMethods (): Promise<void> {
    try {
      this.$emit('saving', true)
      this.paymentMethods = await this.checkoutService.loadPaymentMethods(this.cart.total.value)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }
}

export default PaymentStepTwo
