import { VueConstructor } from 'vue'

import CircleCheckIcon from './icons/CircleCheckIcon.vue'
import CircleInfoIcon from './icons/CircleInfoIcon.vue'

export enum GiftCardInfoType {
  Activated = 'activated', // GiftCard is activated
  AdditionalMethod = 'additionalMethod', // second payment method is required
  ChangeMethod = 'changeMethod', // Info how to pick another payment method
  Exceeded = 'exceeded' // GiftCard's amount exceeds cart total
}

export const giftCardInfoIconsRegistry: Record<string, VueConstructor> = {
  [GiftCardInfoType.Activated]: CircleCheckIcon as VueConstructor,
  [GiftCardInfoType.AdditionalMethod]: CircleInfoIcon as VueConstructor,
  [GiftCardInfoType.Exceeded]: CircleInfoIcon as VueConstructor,
  [GiftCardInfoType.ChangeMethod]: CircleInfoIcon as VueConstructor
}
