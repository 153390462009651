






























import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import { PaymentMethodCode, PaymentMethodPayload } from '../../../../contexts'

import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { Inject, logger } from '../../../../support'
import { Loader } from '../../../shared/molecules/Loader/Loader.vue'
import { StructureConfigurable } from '../../../../support/mixins'

import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'

import { AbstractStep } from '../AbstractStep'
import {
  defaultConfig, GIFT_CARD,
  PAYMENT_STEP_CONFIG_KEY,
  PRZELEWY24,
  Przelewy24ExcludedMethods
} from './PaymentStep.config'
import { defaultPaymentDriver, paymentsDriversRegistry } from './drivers/drivers'
import { PaymentMethod } from './PaymentStep.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<PaymentStep>({
  name: 'PaymentStep',
  components: { Loader },
  created (): void {
    this.config = this.getComponentConfig(PAYMENT_STEP_CONFIG_KEY, defaultConfig)
  }
})
export class PaymentStep extends Mixins(AbstractStep, CartAnalyticsMixin, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public errors: string[] = []
  public hasError: boolean = false
  public isLoading: boolean = false

  // public selectedPayment: PaymentMethodPayload | null = this.payload.payment

  /**
   * Determines whether selected shipping method should reduce available payment methods
   */
  public get shouldReducePaymentMethods (): boolean {
    return this.getConfigProperty<boolean>('shouldReducePaymentMethods')
  }

  /**
   * Map containing availavle payment method codes for shipping methods
   */
  public get shippingsPaymentsMap (): Record<string, Array<string>> {
    return this.getConfigProperty<Record<string, Array<string>>>('shippingsPaymentsMap')
  }

  public get shouldDefaultAcceptConsent (): boolean {
    return this.getConfigProperty('shouldDefaultAcceptConsent')
  }

  /**
   * Determines whether to use Gift Cards as payment method
   */
  public get useGiftCardsForPayment (): boolean {
    return this.getConfigProperty('useGiftCardsForPayment')
  }

  public get paymentsOrder (): Record<string | number, number> | null {
    return this.getConfigProperty('paymentsOrder')
  }

  public get shouldSortMethods (): boolean {
    return !!this.paymentsOrder
  }

  public get selectedPayment (): PaymentMethodPayload | null {
    return this.payload.payment
  }

  public set selectedPayment (payment: any) {
    this.onChange('payment', payment)
  }

  public callbacks: CheckoutStepCallback[] = []

  public get selectedPaymentCode (): PaymentMethodCode | string | null {
    if (this.selectedPayment && this.selectedPayment.code === 'free') {
      return GIFT_CARD
    }

    if (this.doesGiftCartValueExceedSubtotal && this.cart.getGiftCard().length > 0) {
      return GIFT_CARD
    }

    if (this.payload.payment && this.payload.payment.code === PRZELEWY24 &&
      this.payload.payment.methodId && Przelewy24ExcludedMethods.includes(Number(this.payload.payment.methodId))) {
      return String(this.payload.payment.methodId)
    }
    return this.payload.payment?.code ?? null
  }

  public get isDisabled (): boolean {
    return !this.isValidStep()
  }

  public get supportedPaymentMethods (): string[] {
    const methods = this.getConfigProperty<string[]>('drivers')
    // todo: zweryfikowac czy to sprawdzenie nie jest potrzebne w Lirene - Aelia ma paczkomat za pobraniem, Lirene nie
    // if (this.payload.shipping && this.payload.shipping.carrierCode === ShippingMethodCode.Paczkomat) {
    //   return methods.filter((m) => m !== PaymentMethodCode.OnDelivery)
    // }

    if (this.shouldReducePaymentMethods) {
      const selectedShipping = this.payload.shipping ? this.payload.shipping.methodCode : ''
      if (this.shippingsPaymentsMap && typeof this.shippingsPaymentsMap[selectedShipping] !== 'undefined') {
        return this.shippingsPaymentsMap[selectedShipping]
      }
    }

    return methods
  }

  public get supportedPaymentMethodsIcons (): AnyObject {
    return this.getConfigProperty<AnyObject>('icons')
  }

  public get methods (): PaymentMethod[] {
    const unorderedMethods = this.cart?.availablePaymentMethods
      .filter(({ code }) => this.supportedPaymentMethods.includes(code))
      .map((method: PaymentMethodPayload<string>) => ({
        id: method.code,
        title: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.title`),
        description: this.$t(`front.checkout.organisms.PaymentStep.method.${method.code}.description`),
        image: this.supportedPaymentMethodsIcons[method.code],
        driver: method.code in paymentsDriversRegistry ? paymentsDriversRegistry[method.code]
          : defaultPaymentDriver
      }))

    return this.supportedPaymentMethods.map((code) => unorderedMethods.filter((m) => m.id === code)[0])
  }

  public get doesGiftCartValueExceedSubtotal (): boolean {
    return this.cart.getGiftCardValue() > this.cart.getSubtotalPrice()
  }

  public shouldDisablePayment (method: string): boolean {
    return method !== GIFT_CARD && this.cart.getTotalPrice() === 0
  }

  public onModelUpdate (value: PaymentMethodCode | number): void {
    if (isFinite(Number(value)) && (Przelewy24ExcludedMethods.includes(Number(value)))) {
      this.selectedPayment = {
        ...this.cart?.availablePaymentMethods.find(method => method.code === PRZELEWY24),
        methodId: value
      } ?? null
    } else {
      this.selectedPayment = this.cart?.availablePaymentMethods.find(method => method.code === value) ?? null
    }
    this.$nextTick(this.submit)
  }

  public onSaving (value: boolean): void {
    this.$emit('saving', value)
  }

  public static isValidStep (payload: AnyObject): boolean {
    return payload.payment !== null &&
      typeof payload.payment.code === 'string' && payload.payment.code.length > 0
  }

  public async submit (): Promise<void> {
    if (!this.selectedPayment) {
      return
    }

    if (!this.doesGiftCartValueExceedSubtotal && !this.isValidStep()) {
      return
    }

    this.errors = []
    this.hasError = false

    try {
      this.$emit('saving', true)

      if (this.callbacks.length > 0) {
        let cart
        for (const c of this.callbacks) {
          const returned = await c.callable.apply(c.onThis, c.args)
          if (typeof returned === 'object' && returned !== null) {
            cart = returned
          }
        }

        this.$store.commit(CartMutationTypes.SetCart, cart)
      }

      this.eventBus.emit('app:checkout.addPaymentInfo', {
        ...this.getBaseCheckoutPayload(this.cart),
        shippingTier: this.cart.selectedShippingMethod?.methodCode,
        paymentMethod: this.selectedPayment.code
      })
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'warning')
      this.hasError = true
      this.$emit('error', (e as Error).message)
    } finally {
      this.$emit('saving', false)
    }
  }

  public goToNextStep (): void {
    if (!this.payload.payment || this.payload.payment.code.length === 0) {
      this.errors = [this.$t('front.checkout.organisms.PaymentStep.error').toString()]
      return
    }
    //
    this.nextStep()
  }

  protected isValidStep (): boolean {
    return this.selectedPayment !== null && this.selectedPayment.code.length > 0
  }
}

export default PaymentStep
