
import { Component } from 'vue-property-decorator'

import { PaymentMethodCode } from '../../../../../contexts'

import { PRZELEWY24 } from '../PaymentStep.config'
import { PaymentBaseDriver } from './BaseDriver.vue'

/**
 * Driver for single method for driver `Przelewy24General`
 *
 * @summary - This driver is just a simple representation of each method of P24
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Przelewy24SingleMethod>({
  name: 'Przelewy24SingleMethod'
})
export class Przelewy24SingleMethod extends PaymentBaseDriver {
  /**
   * @override
   */
  protected async selectCandidate (): Promise<void> {
    this.setPaymentMethod([this.cart.id, { code: PRZELEWY24 as PaymentMethodCode }],
      {
        type: PRZELEWY24,
        data: {
          method: this.method.id,
          regulationAccept: this.consentAccepted
        }
      })
    // try {
    //   this.$emit('saving', true)
    //   const cart = await this.checkoutService.setPaymentMethod(
    //     this.cart.id,
    //     { code: PRZELEWY24 as PaymentMethodCode },
    //     {
    //       type: PRZELEWY24,
    //       data: {
    //         method: this.method.id,
    //         regulationAccept: false
    //       }
    //     }
    //   )
    //
    //   this.$store.commit(CartMutationTypes.SetCart, cart)
    //   this.resolveCandidate(true)
    // } catch (e) {
    //   logger(e, 'error')
    //   this.resolveCandidate(false)
    // } finally {
    //   this.$emit('saving', false)
    // }
  }
}

export default Przelewy24SingleMethod
