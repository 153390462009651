












import { Component } from 'vue-property-decorator'
import { PaymentBaseDriver } from './BaseDriver.vue'

@Component<Przelewy24BlikWhitelabel>({ name: 'Przelewy24BlikWhitelabel' })
export class Przelewy24BlikWhitelabel extends PaymentBaseDriver {
  /**
   * @override
   */
  protected selectCandidate () {
    this.setPaymentMethod([this.cart.id, { code: this.method.id }], {
      type: this.method.id,
      data: {
        regulationAccept: this.consentAccepted
      }
    })
  }
}

export default Przelewy24BlikWhitelabel
